import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useMemo, useRef, } from 'react';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { Wizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import StepWithRefHOC from '@shared/ui/settings/StepWithRefHOC';
import Initial from './Initial';
import Final from './Final';
import { SIDEBAR_INNER_PROPS } from './constants';
import Warning from './Warning';
const InnerElement = (({ initValues }, ref) => (_jsxs(Wizard, { children: [_jsx(StepWithRefHOC, { Element: Initial, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: Final, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: Warning, initValues: initValues, ref: ref })] })));
const EditPaymentMethodComponent = forwardRef(InnerElement);
const EditPaymentMethod = ({ onClose }) => {
    const { paymentMethodFormStep, paymentMethodFormFields } = useAppSelector(state => state.user);
    const stepsRef = useRef(null);
    const handleClose = () => {
        if (stepsRef === null || stepsRef === void 0 ? void 0 : stepsRef.current) {
            stepsRef.current.handleClose();
            onClose();
        }
    };
    const sidebarInnerProps = useMemo(() => {
        if (typeof paymentMethodFormStep === 'number') {
            return Object.assign({}, SIDEBAR_INNER_PROPS[paymentMethodFormStep]);
        }
        return {};
    }, [paymentMethodFormStep]);
    return (_jsx(SidebarInner, Object.assign({}, sidebarInnerProps, { cancelBtnProps: { onClick: handleClose }, children: paymentMethodFormFields ? (_jsx(EditPaymentMethodComponent, { ref: stepsRef, initValues: paymentMethodFormFields })) : null })));
};
export default EditPaymentMethod;
